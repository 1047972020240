import Service from '@/helpers/service'

export default {
    get(page, params) {
        return Service().get('admin/manage-permission?page=' + page, {params});
    },
    getBySubMenu(subMenuId) {
        return Service().get('admin/get-permission-by-sub-menu/'+subMenuId);
    }, 
    show(id) {
        return Service().get('admin/manage-permission/show/'+id);
    },    
    create(params) {
        return Service().post('admin/manage-permission/create', params);
    },
    update(id, params) {
        return Service().post('admin/manage-permission/update/'+id, params);
    },
    delete(id) {
        return Service().post('admin/manage-permission/delete/'+id);
    }
}